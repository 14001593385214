












































import { Component, Prop, Watch } from 'vue-property-decorator';

import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import RunPipelinesModal from '@/components/modals/RunPipelinesModal.vue';
import Project from '@/models/Project';
import { hasPermission } from '@/auth/AuthService';
import { PossibleAction } from '@/auth/PossibleAction';
import Model from '@/models/Model';
import ProjectService from '@/services/ProjectService';
import ModelService from '@/services/ModelService';
import PipelineService from '@/services/PipelineService';
import PipelineExecution from '@/models/pipelines/PipelineExecution';
import moment from 'moment';
import PipelineState from '@/components/pipelines/PipelineState.vue';

@Component({
  components: { PipelineState, RunPipelinesModal },
})
export default class PipelineExecutions extends mixins(Toasts) {
  protected readonly PossibleAction = PossibleAction;

  @Prop({ required: true })
  protected readonly projectId!: string;
  @Prop({ required: true })
  protected readonly modelId!: string;

  protected readonly breadcrumbs = [
    { text: 'Home', to: '/' },
    { text: 'Project', to: '/project/details/' + this.projectId },
    { text: 'Model', to: '/project/editor/' + this.projectId + '/' + this.modelId },
    { text: 'Pipeline Executions', active: true },
  ];

  protected project: Project | null = null;
  protected model: Model | null = null;
  protected pipelineExecutions: PipelineExecution[] | null = null;

  protected pipelineExecutionFields = [
    {
      key: 'state',
      label: 'State',
      sortable: true,
    },
    {
      key: 'pipelineType',
      label: 'Type',
      sortable: true,
    },
    {
      key: 'started',
      label: 'Start',
      sortable: true,
      formatter: this.formatTimestamp,
    },
    {
      key: 'lastUpdated',
      label: 'Updated',
      sortable: true,
      formatter: this.formatTimestamp,
    },
  ];

  @Watch('modelId')
  protected handleModelIdChange(): void {
    this.loadModel();
    this.loadPipelineExecutions();
  }

  @Watch('projectId')
  protected handleProjectIdChange(): void {
    this.loadProject();
  }

  mounted(): void {
    this.loadProject();
    this.loadModel();
    this.loadPipelineExecutions();
  }

  protected shouldBeVisible(action: PossibleAction, project?: Project): boolean {
    const permission = hasPermission(action, project);
    return permission != null && permission;
  }

  protected loadModel(): void {
    ModelService.getById(this.modelId)
      .then((model) => {
        this.model = model;
      })
      .catch((backendError) => {
        this.$router.replace({ name: '/' }).then(() => {
          this.showToast('Unknown Error', 'Oops, something failed: ' + backendError.response.status, 'danger');
        });
      });
  }

  protected loadProject(): void {
    ProjectService.getById(parseInt(this.projectId))
      .then((project) => {
        this.project = project;
      })
      .catch((backendError) => {
        this.$router.replace({ name: '/' }).then(() => {
          this.showToast('Unknown Error', 'Oops, something failed: ' + backendError.response.status, 'danger');
        });
      });
  }

  protected loadPipelineExecutions(): void {
    PipelineService.getExecutions(null, this.modelId, parseInt(this.projectId), null)
      .then((executions) => {
        this.pipelineExecutions = executions;
      })
      .catch((backendError) => {
        this.$router.replace({ name: '/' }).then(() => {
          this.showToast('Unknown Error', 'Oops, something failed: ' + backendError.response.status, 'danger');
        });
      });
  }

  protected handleSelection(selected: PipelineExecution[]): void {
    const selectedExecution = selected.pop() ?? null;
    if (selectedExecution != null) {
      this.showDetails(selectedExecution.executionId);
    }
  }

  protected showDetails(executionId: string): void {
    this.$router.push({
      name: 'pipelineExecutionDetails',
      params: { projectId: this.projectId, modelId: this.modelId, executionId: executionId },
    });
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  protected openRunPipelinesModal(): void {
    this.$bvModal.show('run-pipelines-modal');
  }

  protected formatTimestamp(date: Date | null): string {
    if (date == null) {
      return '-';
    }
    return moment(date).format('DD.MM.YYYY HH:mm:ss');
  }
}
